

#slideshow {
	margin: 0 auto;
	height: 350px;
	width: 100%;
	background-color: transparent;
	box-sizing: border-box;
}

.slideshow-title {
	font-family: 'Allerta Stencil';
	font-size: 62px;
	color: #fff;
	margin: 0 auto;
	text-align: center;
	margin-top: 25%;
	letter-spacing: 3px;
	font-weight: 300;
}

.sub-heading {
	padding-top: 50px;
	font-size: 18px;
}
.sub-heading-two { font-size: 15px; }
.sub-heading-three { font-size: 13px; }
.sub-heading-four { font-size: 11px; }
.sub-heading-five { font-size: 9px; }
.sub-heading-six { font-size: 7px; }
.sub-heading-seven { font-size: 5px; }
.sub-heading-eight { font-size: 3px; }
.sub-heading-nine { font-size: 1px; }

.entire-content {
	margin: auto;
	width: 190px;
	perspective: 1000px;
	position: relative;
	padding-top: 0px;
}

.content-carrousel {
	width: 100%;
	position: absolute;
	float: right;
	animation: rotar 12s infinite linear;
	transform-style: preserve-3d;
}

.content-carrousel:hover {
	animation-play-state: paused;
	cursor: pointer;
}

.content-carrousel figure {
	width: 100%;
	height: 220px;
	border: 1px solid #3b444b;
	overflow: hidden;
	position: absolute;
}

.content-carrousel figure:nth-child(1) { transform: rotateY(0deg) translateZ(200px); } 
.content-carrousel figure:nth-child(2) { transform: rotateY(60deg) translateZ(200px); } 
.content-carrousel figure:nth-child(3) { transform: rotateY(120deg) translateZ(200px); } 
.content-carrousel figure:nth-child(4) { transform: rotateY(180deg) translateZ(200px); } 
.content-carrousel figure:nth-child(5) { transform: rotateY(240deg) translateZ(200px); } 
.content-carrousel figure:nth-child(6) { transform: rotateY(300deg) translateZ(200px); }

.shadow {
    position: absolute;
    box-shadow: 0px 0px 20px 0px #000;
    border-radius:20px;
}

.content-carrousel img {
	image-rendering: auto;
	transition: all 1000ms;
	width: 100%;
	height: 100%;
}

.content-carrousel img:hover {
	transform: scale(1.2);
	transition: all 800ms;
}

@keyframes rotar {
	from {
		transform: rotateY(0deg);
	} to {
		transform: rotateY(360deg);
	}
} 